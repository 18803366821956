<template>
  <div>
    <h4 class="pb-4">Latest registered 5 accounts</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Account No</th>
            <th scope="col">Consumer Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Connection Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in consumers" :key="customer.id">
            <td>
              <router-link :to="'/consumers/' +  customer.id">{{ customer.account_number }}  </router-link>
            </td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.mobile_number }}</td>
            <td>{{ customer.connection_date | dateFormat }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <router-link to="/consumers">
      <button type="button" class="btn btn-primary float-right view-all">
        View all
      </button>
    </router-link>
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { mapGetters } from "vuex";

export default {
  freeSet,
  computed: {
    ...mapGetters("Consumer", ["consumers"]),
  },
  mounted() {
    this.$store.dispatch("Consumer/getLatestConsumers");
  },
};
</script>

<style lang="scss" scoped>
@media (width: 1366px) {
  div {
    button {
      margin-top: 20px;
    }
  }
}
</style>


