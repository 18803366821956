<template>
  <div>
    <h4 class="text-uppercase">Dashboard</h4>
    <CRow>
      <CCol sm="6" lg="4">
        <CCard class="p-4">
          <CRow>
            <CCol sm="6" lg="5">
               <img
                class="mb-1 pr-0 img"
                name="cil-user"
                :src="$auth.user().photo_url ? $auth.user().photo_url : '/user.PNG'"
              />
              <!-- <img

                name="cil-user"
                src="/user.png"
                alt="User Logo"
              /> -->
              <!-- <CIcon

              /> -->
              <blockquote class="text-left">
                <h4>
                  {{ $auth.user().first_name + " " + $auth.user().last_name }}
                </h4>
                <p class="text-muted">Roles: {{ getRoles }}</p>
              </blockquote>
            </CCol>
            <CCol sm="6" lg="7">
              <div class="custom-alignment">
                <h4>{{ getGreetings }}!</h4>
                <p class="text-muted">Welcome Back</p>
              </div>
            </CCol>
          </CRow>
        </CCard>

        <CCard color="default" class="custom-card-width p-0" body-wrapper>
          <LatestWaterVehicleRrequest />
        </CCard>
      </CCol>
      <CCol sm="6" lg="8">
        <WidgetsDropdown />
        <CCard class="p-0">
          <CCardBody>
            <h4>Consumption Chart</h4>
            <CCardBody><ConsumptionBarChart /></CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="6" lg="6">
        <CCard color="default" class="custom-card p-0" body-wrapper>
          <LatestRegisteredAccount />
        </CCard>
      </CCol>

      <CCol sm="6" lg="6">
        <CCard color="default" class="custom-card p-0" body-wrapper>
          <LatestMeterInstall />
        </CCard>
      </CCol>

<!--      <CCol sm="6" lg="6">
        <CCard color="default" class="custom-card" body-wrapper>
          <TopDue />
        </CCard>
      </CCol>-->
      <CCol sm="6" lg="6">
        <CCard color="default" class="p-0" body-wrapper>
          <TopConsumptions />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LatestRegisteredAccount from "@/components/customers/LatestRegisteredAccount.vue";

const ConsumptionBarChart = () => import(/* webpackChunkName: "ConsumptionBarChart" */ "./charts/ConsumptionBarChart");
const WidgetsDropdown = () => import(/* webpackChunkName: "WidgetsDropdown" */ "./widgets/WidgetsDropdown");
// const WidgetsBrand = () => import(/* webpackChunkName: "WidgetsBrand" */ "./widgets/WidgetsBrand");
const LatestWaterVehicleRrequest = () => import(/* webpackChunkName: "LatestWaterVehicleRrequest" */ "../../src/components/WaterVehicleRequest/LatestWaterVehicleRrequest");
const LatestMeterInstall = () => import(/* webpackChunkName: "LatestMeterInstall" */ "../../src/components/meter/LatestMeterInstall");
const LatestTransaction = () => import(/* webpackChunkName: "LatestTransaction" */ "../../src/components/payment/LatestTransaction");
const TopDue = () => import(/* webpackChunkName: "TopDue" */ "../../src/components/Bill/TopDue");
const TopConsumptions = () => import(/* webpackChunkName: "TopDue" */ "../../src/components/dashboard/TopConsumptions");

export default {
  name: "Dashboard",
  components: {
    LatestRegisteredAccount,
    ConsumptionBarChart,
    WidgetsDropdown,
    LatestWaterVehicleRrequest,
    LatestMeterInstall,
    LatestTransaction,
    TopDue,
    TopConsumptions
  },
  data() {
    return {
      selected: "Month",
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: true}]
    };
  },
  methods: {
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },

  computed: {
    getGreetings() {
      var today = new Date();
      var hourNow = today.getHours();

      if (hourNow < 12) {
        return "Good Morning";
      } else if (hourNow < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    getRoles() {
      let roles = this.$auth.user().roles;
      let roleName = ''
      for(let key in roles) {
        roleName +=  roleName? ', '+roles[key]['display_name']:roles[key]['display_name'];
      }
      return roleName;
    }
  },
  mounted() {
    this.$store.dispatch("Dashboard/resetBarChartData");
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style  scoped>
.card {
  border: none;
  padding: 20px;
}
.custom-card-width {
  min-height: 460px;
}

@media (width: 1366px) {
  .custom-card {
    min-height: 500px;
  }
}

@media (width: 1920px) {
  .custom-card {
    height: 350px;
  }
}

header {
  font-weight: 700;
  font-size: 16px;
}
svg {
  background-color: #556080;
  border: 5px solid #556080;
  fill: green;
  border-radius: 100%;
  height: 80px !important;
  width: 80px !important;
}
svg >>> path {
  fill: white;
}
.custom-alignment {
  text-align: left;
  margin: auto;
  float: right;
  padding-top: 40px;
}
.img {
  border-radius: 50%;
  width: 80px;
}
</style>


